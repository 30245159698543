@font-face {
    font-family: "Banner";
    src: local("Banner"), url(./assets/fonts/banner/roman.ttf) format("truetype");
}
@font-face {
    font-family: "Headline";
    src: local("Headline"), url(./assets/fonts/headline/roman.ttf) format("truetype");
}
* {
    font-family: "Headline", Arial, Helvetica, sans-serif;
    background-color: black;
    color: white;
}
h1,h2,h3 {
    font-family: "Banner", Arial, Helvetica, sans-serif;
}
img {
    max-width: 70%;
    height: auto;
}
body {
    margin: 0;
}
.sidebar {
    margin: 0;
    padding: 4px;
    width: 250px;
    background-color: black;
    position: fixed;
    height: 100%;
    overflow: auto;
}
.sidebar a,li { /*h1,h2,h3,p,*/
    display: block;
    padding: 4px;
    text-decoration: none;
}
.sidebar li {
    border: none;
    cursor: pointer;
    cursor: hand;
    position: left;
    text-align: left;
    font-size: x-large;
}
.sidebardiv {
    padding: 5px;
}
ul {
    list-style: none;
    padding-left: 0;
}
.sidebar .active {
    background-color: grey;
    color: white;
}
.sidebar li:hover:not(.active) {
    background-color: darkgrey;
    color: white;
}
.sidebar p {
    line-height: 1.4;
}
div.content {
    margin-left: 275px;
    padding: 1px 16px;
    height: 1000px;
}
@media screen and (max-width: 700px) {
    .sidebar {
        width: 100%;
        height: auto;
        position: relative;
    }
    .sidebar {float: left;}
    div.content {margin-left: 0;}
}
@media screen and (max-width: 400px) {
    .sidebar {
        text-align: center;
        float: none;
    }
}
.room-text {
    font-style: italic;
}
.page-title {
    font-size: xx-large;
}
.page-text {
    font-style: italic;
    font-weight: bold;
    font-size: x-large;
}
.image-title {
    font-style: italic;
    font-weight: bold;
}
.image-year {
    font-weight: bold;
}
.image-subtitle {
    font-weight: 100;
}
.image-work-details {
    font-weight: 100;
    font-size: small;
}
